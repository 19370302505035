import React from 'react';
import { uniqBy } from "lodash";

const loginPage = React.lazy(() => import('src/pages/login/container'));
const forgotPasswordPage = React.lazy(() => import('src/pages/forgot-password/container'));
const dashboardPage = React.lazy(() => import('src/pages/menu-items/dashboard/container'));
const supportPage = React.lazy(() => import('src/pages/menu-items/support/container'));
const ordersPage = React.lazy(() => import('src/pages/menu-items/orders/container'));
const uploadsPage = React.lazy(() => import('src/pages/menu-items/uploads/container'));
const reportsPage = React.lazy(() => import('src/pages/menu-items/reports/container'));
const ndrUploadPage = React.lazy(() => import('src/pages/menu-items/ndr-upload/container'));
const blockServicePage = React.lazy(() => import('src/pages/menu-items/block-service/container'));
const ndrLogPage = React.lazy(() => import('src/pages/menu-items/ndr-log/container'));
const listShipmentPage = React.lazy(() => import('src/pages/menu-items/list-shipment/container'));
const settingsPage = React.lazy(() => import('src/pages/menu-items/settings/container'));
const addViewCompanyPage = React.lazy(() => import('src/pages/menu-items/add-view-company/container'));
const addViewUserPage = React.lazy(() => import('src/pages/menu-items/add-view-user/container'));
const addViewContractPage = React.lazy(() => import('src/pages/menu-items/add-view-contract/container'));
const userMgmtPage = React.lazy(() => import('src/pages/menu-items/user-mgmt/container'));
const shipperPage = React.lazy(() => import('src/pages/menu-items/shipper/container'));
const featuresPage = React.lazy(() => import('src/pages/menu-items/features/container'));
const whatsappPage = React.lazy(() => import('src/pages/menu-items/whatsapp-int/container'));
const shipmentHistoryPage = React.lazy(() => import('src/pages/menu-items/shipment-history/container'));
const trackingIdUploadPage =  React.lazy(() => import('src/pages/menu-items/add-view-contract/tracking-id-upload/container'));
const callsPage = React.lazy(() => import('src/pages/menu-items/calls/container'));
// const billingPage =  React.lazy(() => import('src/pages/menu-items/billing/container'));
const RoutesFinal = [
  {
    path: '/',
    component: loginPage,
    routes: ''
  },
  {
    path: '/login',
    component: loginPage,
    routes: ''
  },
  {
    path: '/forgotPassword',
    component: forgotPasswordPage,
    routes: ''
  },
  {
    path: '/dashboard',
    component: dashboardPage,
    routes: ''
  },
  {
    path: '/support',
    component: dashboardPage,
    routes: ''
  },
  {
    path: '/settings',
    component: settingsPage,
    routes: ''
  },
  {
    path: '/calls',
    component: callsPage,
    routes: ''
  },
  // {
  //   path : '/billing',
  //   component: billingPage,
  //   routes:''
  // }
];

function prepareRoutes() {
  if (localStorage.getItem('rbac')?.length > 0) {
    JSON.parse(localStorage.getItem('rbac')).map((item, _index) => {
      switch (item.id) {
        case '1':
          RoutesFinal.push({
            path: '/ndr_upload',
            component: ndrUploadPage,
            routes: ''
          },
            {
              path: '/ndr_log',
              component: ndrLogPage,
              routes: ''
            },
            )
          break;
        case '4':
          RoutesFinal.push({
            path: '/orders',
            component: ordersPage,
            routes: ''
          },
            {
              path: '/upload',
              component: uploadsPage,
              routes: ''
            },{
              path: '/shipper',
              component: shipperPage,
              routes: ''
            },{
              path: '/add_view_contract',
              component: addViewContractPage,
              routes: ''
            },   )
          break;
        case '5':
          RoutesFinal.push({
            path: '/orders',
            component: ordersPage,
            routes: ''
          })
          break;
        case '10':
          RoutesFinal.push({
            path: '/add_view_company',
            component: addViewCompanyPage,
            routes: ''
          },
          {
            path: '/features',
            component: featuresPage,
            routes: ''
          })
          break;
        case '2': break;
        case '3':
          RoutesFinal.push({
            path: '/list_shipment',
            component: listShipmentPage,
            routes: ''
          },
            {
              path: '/shipment_history',
              component: shipmentHistoryPage,
              routes: ''
            },)
          break;
        case '6':
          RoutesFinal.push({
            path: '/shipper',
            component: shipperPage,
            routes: ''
          },
            {
              path: '/add_view_user',
              component: addViewUserPage,
              routes: ''
            },
            {
              path: '/add_view_contract',
              component: addViewContractPage,
              routes: ''
            },       
            {
              path: '/tracking_id_upload',
              component: trackingIdUploadPage,
              routes: ''
            },
            {
            path: '/user_mgmt',
            component: userMgmtPage,
            routes: ''
          },
          {
            path: '/block_service',
            component: blockServicePage,
            routes: ''
          })
          break;
        case '7':
          RoutesFinal.push({
            path: '/support',
            component: dashboardPage,
            routes: ''
          })
          break;
        case '8':
        case '9':
          RoutesFinal.push({
            path: '/reports',
            component: reportsPage,
            routes: ''
          })
          break;
        case '16':
            RoutesFinal.push({
              path: '/whatsapp',
              component: whatsappPage,
              routes: ''
            })
            break;
            case '17':
              RoutesFinal.push({
                path: '/orders',
                component: ordersPage,
                routes: ''
              })
              break;
            case '18':
              RoutesFinal.push({
                path: '/list_shipment',
                component: listShipmentPage,
                routes: ''
              },
                {
                  path: '/shipment_history',
                  component: shipmentHistoryPage,
                  routes: ''
                },)
              break;
      }
    })
  }
}

prepareRoutes();
const RoutesFinally = uniqBy(RoutesFinal, 'path');
export default RoutesFinally;
